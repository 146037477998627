<template>
  <ul class="menu-nav">
    <!-- begin::Dashboard -->
    <router-link
      v-slot="{ href, navigate, isActive, isExactActive }"
      to="/dashboard"
      custom
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="svg-icon menu-icon">
            <!--begin::Svg Icon-->
            <inline-svg :src="'/media/svg/icons/Layout/Layout-4-blocks.svg'" />
            <!--end::Svg Icon-->
          </span>
          <span class="menu-text">{{ transChoice('global.dashboard', 1) | capitalize }}</span>
        </a>
      </li>
    </router-link>
    <!-- end::Dashboard -->

    <!-- begin::Tasks -->
    <router-link
      v-slot="{ href, navigate, isActive, isExactActive }"
      to="/tasks"
      custom
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="svg-icon menu-icon">
            <!--begin::Svg Icon-->
            <inline-svg :src="'/media/svg/icons/Communication/Clipboard-check.svg'" />
            <!--end::Svg Icon-->
          </span>
          <span class="menu-text">{{ transChoice('global.task', 2) | capitalize }}</span>
          <span v-if="countDone" class="menu-label">
            <span
              class="label label-rounded"
              :class="[pendingTasksCount > 0 ? 'label-primary' : 'label-success']"
            >
              {{ pendingTasksCount }}
            </span>
          </span>
        </a>
      </li>
    </router-link>
    <!-- end::Tasks -->

    <!-- begin::Templates -->
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      :class="{ 'menu-item-open': hasActiveChildren('/templates') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="svg-icon menu-icon">
          <!--begin::Svg Icon-->
          <inline-svg :src="'/media/svg/icons/Design/Layers.svg'" />
          <!--end::Svg Icon-->
        </span>
        <span class="menu-text">{{ transChoice('global.template', 2) | capitalize }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <i class="menu-arrow"></i>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">{{ transChoice('global.template', 2) | capitalize }}</span>
            </span>
          </li>

          <!-- begin::All templates -->
          <router-link
            v-slot="{ href, navigate, isActive, isExactActive }"
            to="/templates"
            custom
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ trans('nav.aside.templates.all') }}</span>
              </a>
            </li>
          </router-link>
          <!-- end::All templates -->

          <!-- begin::Legg til nytt templat -->
          <router-link
            v-slot="{ isActive, isExactActive }"
            to="/templates/add"
            custom
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <b-link
                class="menu-link"
                @click="$bvModal.show('modal-templates-add')"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ trans('nav.aside.templates.add') }}</span>
              </b-link>
            </li>
          </router-link>
          <!-- end::Legg til nytt templat -->
        </ul>
      </div>
    </li>
    <!-- end::Templates -->

    <!-- begin::Products -->
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      :class="{ 'menu-item-open': hasActiveChildren('/products') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="svg-icon menu-icon">
          <!--begin::Svg Icon-->
          <inline-svg :src="'/media/svg/icons/Shopping/Box1.svg'" />
          <!--end::Svg Icon-->
        </span>
        <span class="menu-text">{{ transChoice('global.product', 2) | capitalize }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <i class="menu-arrow"></i>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">{{ transChoice('global.product', 2) | capitalize }}</span>
            </span>
          </li>

          <!-- begin::Alle produkter -->
          <router-link
            v-slot="{ href, navigate, isExactActive }"
            to="/products"
            custom
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ trans('nav.aside.products.all') }}</span>
              </a>
            </li>
          </router-link>
          <!-- end::Alle produkter -->

          <!-- begin::Synchronize products -->
          <router-link
            v-slot="{ href, navigate, isExactActive }"
            to="/products/sync"
            custom
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ trans('nav.aside.products.sync') }}</span>
              </a>
            </li>
          </router-link>
          <!-- end::Synchronize products -->
        </ul>
      </div>
    </li>
    <!-- end::Products -->

    <!-- begin::Customers -->
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      :class="{ 'menu-item-open': hasActiveChildren('/customers') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="svg-icon menu-icon">
          <!--begin::Svg Icon-->
          <inline-svg :src="'/media/svg/icons/Files/User-folder.svg'" />
          <!--end::Svg Icon-->
        </span>
        <span class="menu-text">{{ transChoice('global.customer', 2) | capitalize }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <i class="menu-arrow"></i>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">{{ transChoice('global.customer', 2) | capitalize }}</span>
            </span>
          </li>

          <!-- begin::All Customers -->
          <router-link
            v-slot="{ href, navigate, isExactActive }"
            to="/customers"
            custom
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ trans('nav.aside.customers.all', 2) | capitalize }}</span>
              </a>
            </li>
          </router-link>
          <!-- end::All Customers -->

          <!-- begin::Synchronize customers -->
          <router-link
            v-slot="{ href, navigate, isExactActive }"
            to="/customers/sync"
            custom
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ trans('nav.aside.customers.sync') }}</span>
              </a>
            </li>
          </router-link>
          <!-- end::Synchronize customers -->
        </ul>
      </div>
    </li>
    <!-- end::Customers -->

    <!-- begin::Sessions -->
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      :class="{ 'menu-item-open': hasActiveChildren('/sessions') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="svg-icon menu-icon">
          <!--begin::Svg Icon-->
          <inline-svg :src="'/media/svg/icons/General/User.svg'" />
          <!--end::Svg Icon-->
        </span>
        <span class="menu-text">{{ transChoice('global.session', 2) | capitalize }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <i class="menu-arrow"></i>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">{{ transChoice('global.session', 2) | capitalize }}</span>
            </span>
          </li>

          <!-- begin::All Sessions -->
          <router-link
            v-slot="{ href, navigate, isActive, isExactActive }"
            to="/sessions"
            custom
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ trans('nav.aside.sessions.all', 2) | capitalize }}</span>
              </a>
            </li>
          </router-link>
          <!-- end::All Sessions -->
        </ul>
      </div>
    </li>
    <!-- end::Sessions -->

    <!-- begin::Logs -->
    <router-link
      v-slot="{ href, navigate, isActive, isExactActive }"
      to="/logs"
      custom
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="svg-icon menu-icon">
            <!--begin::Svg Icon-->
            <inline-svg :src="'/media/svg/icons/Communication/Archive.svg'" />
            <!--end::Svg Icon-->
          </span>
          <span class="menu-text">{{ transChoice('global.log', 0) | capitalize }}</span>
        </a>
      </li>
    </router-link>
    <!-- end::Logs -->
  </ul>
</template>

<script>
  import { trans, transChoice } from '@src/core/helpers/translate'
  import { ref, computed } from '@vue/composition-api'
  import { TASKS } from '@src/store/tasks'

  export default {
    name: 'KTMenu',
    setup (props, { root }) {
      const pendingTasksCount = computed(() => Object.keys(root.$store.state.tasks.active).length)
      const countDone = ref(false)

      root.$store.dispatch(TASKS.ACTIONS.FETCH_TASKS, {
        status: 0, // active tasks
        limit: false // get all - no pagination
      }).then(() => {
        countDone.value = true
      }).catch(() => {
        console.log('ERROR While fetching tasks')
      })


      return {
        countDone,
        pendingTasksCount,
        trans,
        transChoice
      }
    },
    methods: {
      hasActiveChildren (match) {
        // return this.$route.path.includes(match) // !== -1
        return this.$route.path.startsWith(match)
      }
    }
  }
</script>
