<template>
  <!-- begin:: Aside -->
  <div
    id="kt_aside"
    ref="kt_aside"
    class="aside aside-left aside-fixed d-flex flex-column flex-row-auto"
    @mouseover="mouseEnter"
    @mouseleave="mouseLeave"
  >
    <!-- begin:: Aside -->
    <!-- <KTBrand></KTBrand>-->
    <div class="brand flex-column-auto" id="kt_brand" ref="kt_brand">
      <div class="brand-logo">
        <router-link
          to="/"
          custom
        >
          <Logo :width="160" text-color="#303440" dot-color="#1A73E8" />
        </router-link>
      </div>
      <div class="brand-tools" v-if="allowMinimize">
        <button
          class="brand-toggle btn btn-sm px-0"
          id="kt_aside_toggle"
          ref="kt_aside_toggle"
        >
        <span class="svg-icon svg-icon svg-icon-xl">
          <inline-svg
            class="svg-icon"
            src="/media/svg/icons/Navigation/Angle-double-left.svg"
          />
        </span>
        </button>
      </div>
    </div>
    <!-- end:: Aside -->

    <!-- begin:: Aside Menu -->
    <div
      id="kt_aside_menu_wrapper"
      class="aside-menu-wrapper flex-column-fluid"
    >
      <div
        id="kt_aside_menu"
        ref="kt_aside_menu"
        class="aside-menu my-4"
        data-menu-vertical="1"
        data-menu-dropdown-timeout="500"
        :class="asideMenuClass"
      >
        <!-- example static menu here -->
        <perfect-scrollbar
          class="aside-menu scroll"
          style="max-height: 90vh; position: relative;"
        >
          <KTMenu></KTMenu>
        </perfect-scrollbar>
      </div>
    </div>
    <!--begin::Modals-->
    <ModalAddTemplate></ModalAddTemplate>
    <!--end::Modals-->
  </div>
  <!-- end:: Aside -->
</template>

<script>
  import { mapGetters } from 'vuex'
  import KTMenu from '@src/components/layout/aside/Menu.vue' // Custom
  import Logo from '@src/components/core/Logo'
  // import KTBrand from '@/view/layout/brand/Brand.vue'
  import KTLayoutAside from '@/assets/js/layout/base/aside.js'
  import KTLayoutAsideMenu from '@/assets/js/layout/base/aside-menu.js'
  import KTLayoutAsideToggle from '@/assets/js/layout/base/aside-toggle'
  import KTLayoutBrand from '@/assets/js/layout/base/brand'
  import ModalAddTemplate from '@src/components/modals/templates/Add'

  export default {
    name: 'KTAside',
    components: {
      // KTBrand,
      Logo,
      KTMenu,
      ModalAddTemplate
    },
    data () {
      return {
        insideTm: 0,
        outsideTm: 0
      }
    },
    computed: {
      ...mapGetters([
        'layoutConfig',
        'getClasses'
      ]),

      allowMinimize () {
        return Boolean(this.layoutConfig('aside.self.minimize.toggle'))
      },
      /**
       * Get extra classes for menu based on the options
       */
      asideMenuClass () {
        const classes = this.getClasses('aside_menu')
        if (typeof classes !== 'undefined') {
          return classes.join(' ')
        }
        return null
      }
    },
    mounted () {
      // Init Brand Panel For Logo
      KTLayoutBrand.init(this.$refs.kt_brand)

      // Init Aside Menu Toggle
      KTLayoutAsideToggle.init(this.$refs.kt_aside_toggle)

      this.$nextTick(() => {
        // Init Aside
        KTLayoutAside.init(this.$refs.kt_aside)

        // Init Aside Menu
        KTLayoutAsideMenu.init(this.$refs.kt_aside_menu)
      })
    },
    methods: {
      /**
       * Use for fixed left aside menu, to show menu on mouseenter event.
       */
      mouseEnter () {
        // check if the left aside menu is fixed
        if (document.body.classList.contains('aside-fixed')) {
          if (this.outsideTm) {
            clearTimeout(this.outsideTm)
            this.outsideTm = null
          }

          // if the left aside menu is minimized
          if (document.body.classList.contains('aside-minimize')) {
            document.body.classList.add('aside-minimize-hover')

            // show the left aside menu
            document.body.classList.remove('aside-minimize')
          }
        }
      },

      /**
       * Use for fixed left aside menu, to show menu on mouseenter event.
       */
      mouseLeave () {
        if (document.body.classList.contains('aside-fixed')) {
          if (this.insideTm) {
            clearTimeout(this.insideTm)
            this.insideTm = null
          }

          // if the left aside menu is expand
          if (document.body.classList.contains('aside-minimize-hover')) {
            // hide back the left aside menu
            document.body.classList.remove('aside-minimize-hover')
            document.body.classList.add('aside-minimize')
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.aside-toggle {
  outline: none;
}

.aside-minimize:not(.aside-minimize-hover) .brand .brand-logo a div {
  display: none;
}
</style>
