<template>
  <div class="custom-logo" :style="style">
    <!--eslint-disable-->
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 426.9 126.2"
      style="enable-background:new 0 0 426.9 126.2;"
      xml:space="preserve"
    >
      <g id="Group_7" transform="translate(-236.397 -248.381)">
        <g id="Group_2" transform="translate(236.397 274.061)">
          <g id="Group_1">
            <path id="Path_1" :style="'fill: ' + textColor" d="M23.1,67.9L1.1,17.8h7.4l15.5,35.5L40,17.8h7.3L24.9,67.9H23.1z"/>
            <path id="Path_2" :style="'fill: ' + textColor" d="M51.4,67.6V17.8h7.1v49.8H51.4z"/>
            <path id="Path_3" :style="'fill: ' + textColor" d="M66.8,63.7v-9.4c1.8,2.2,4,4.1,6.5,5.6c2,1.1,4.3,1.7,6.6,1.7c2.1,0.1,4.2-0.5,5.8-1.7
              c1.4-1.1,2.2-2.9,2.1-4.7c0-1.5-0.5-3-1.4-4.3c-2-2.1-4.4-3.9-6.9-5.3c-5.6-3.6-9.2-6.4-10.6-8.4c-1.5-2.1-2.2-4.6-2.2-7.1
              c-0.1-3.6,1.4-7.1,4.1-9.5c2.9-2.5,6.7-3.9,10.6-3.7c1.9,0,3.8,0.3,5.7,0.8c2,0.6,3.9,1.5,5.6,2.6v8.1c-1.8-1.5-3.9-2.8-6.1-3.8
              c-1.8-0.8-3.6-1.2-5.6-1.2c-2-0.1-3.9,0.5-5.5,1.7c-1.4,1-2.1,2.6-2.1,4.3c0,2.5,2.9,5.5,8.8,9.2l1.1,0.6c4.8,3,7.9,5.6,9.4,7.9
              c1.5,2.5,2.3,5.5,2.2,8.4c0.1,3.6-1.4,7-4.1,9.4c-2.9,2.5-6.7,3.9-10.6,3.7c-2.5,0-4.9-0.4-7.3-1.1
              C70.8,66.5,68.7,65.3,66.8,63.7z"/>
            <path id="Path_4" :style="'fill: ' + textColor" d="M145.4,55.8v8.1c-2.8,1.5-5.8,2.7-8.9,3.5c-2.9,0.8-6,1.2-9,1.2c-2.8,0-5.5-0.4-8.2-1.1
              c-2.6-0.8-5.1-1.9-7.3-3.3c-3.6-2.3-6.6-5.5-8.6-9.3c-2.1-3.8-3.1-8-3.1-12.3c0-6.8,2.8-13.3,7.8-18c2.6-2.5,5.6-4.4,8.9-5.6
              c3.5-1.3,7.2-1.9,11-1.9c2.8,0,5.6,0.3,8.3,1c3,0.7,5.9,1.7,8.7,3v7.7c-2.8-1.6-5.8-2.8-8.9-3.8c-2.6-0.8-5.3-1.2-8.1-1.2
              c-5.3-0.2-10.5,1.8-14.4,5.4c-7.5,7.4-7.6,19.5-0.2,27c0,0,0.1,0.1,0.1,0.1c3.8,3.6,8.8,5.6,14.1,5.4c3,0,6-0.5,8.8-1.5
              C139.6,59.1,142.6,57.6,145.4,55.8z"/>
            <path id="Path_5" :style="'fill: ' + textColor" d="M153.6,67.6V17.8h30v6.3h-22.9v15.1h22.3v6.4h-22.3v15.5h23.6v6.4L153.6,67.6z"/>
            <path id="Path_6" :style="'fill: ' + textColor" d="M193.3,67.6V17.8h6.2l32.8,38.7V17.8h7.1v49.8h-6.3l-32.7-38.7v38.7L193.3,67.6z"/>
            <path id="Path_7" :style="'fill: ' + textColor" d="M243.1,67.6l21.6-49.8h5.2l21.9,49.8h-7.4L278,52.9h-21.2l-6.3,14.7H243.1z M259.5,46.5H275
              l-7.9-17.9L259.5,46.5z"/>
            <path id="Path_8" :style="'fill: ' + textColor" d="M295.4,67.6V17.8H310c5,0,8.9,1.2,11.6,3.6c2.8,2.6,4.3,6.3,4.1,10.1c0.1,2.8-0.7,5.6-2.3,7.9
              c-1.8,2.2-4.3,3.8-7.1,4.5c0.9,0.5,1.7,1.1,2.3,1.8c1.1,1.2,2,2.4,2.8,3.8l12.1,18.2h-8.6l-8.5-13.1c-1.6-2.8-3.7-5.4-6.2-7.6
              c-1.7-1.1-3.6-1.7-5.6-1.6h-2.1v22.3L295.4,67.6z M302.5,39.3h5.9c2.6,0.2,5.3-0.5,7.5-1.9c3.2-3.2,3.3-8.5,0.1-11.8
              c-2.3-1.4-4.9-2.1-7.6-1.8h-5.9V39.3z"/>
            <path id="Path_9" :style="'fill: ' + textColor" d="M370.7,42.5c0-3.3,0.7-6.6,2-9.7c1.3-3.1,3.3-5.9,5.8-8.3c2.5-2.4,5.4-4.3,8.6-5.6
              c3.2-1.3,6.7-1.9,10.1-1.9c3.5,0,7,0.6,10.3,1.9c6.4,2.6,11.6,7.6,14.4,14c1.3,3.2,2,6.6,2,10c0,3.4-0.7,6.7-2,9.8
              c-1.3,3.1-3.3,5.9-5.7,8.2c-2.5,2.4-5.4,4.3-8.7,5.6c-3.3,1.3-6.7,1.9-10.2,1.9c-3.5,0-6.9-0.7-10.2-2c-3.2-1.3-6.1-3.2-8.6-5.6
              C373.5,56.1,370.7,49.5,370.7,42.5L370.7,42.5z M378.3,42.7c0,2.5,0.5,5,1.4,7.3c0.9,2.3,2.3,4.4,4.1,6.1
              c1.7,1.8,3.8,3.2,6.1,4.1c2.3,1,4.8,1.5,7.3,1.4c2.5,0,5-0.5,7.4-1.4c2.3-1,4.4-2.4,6.1-4.1c1.8-1.8,3.2-3.8,4.1-6.1
              c1.9-4.6,1.9-9.8,0-14.5c-1-2.3-2.4-4.4-4.2-6.2c-1.7-1.8-3.8-3.2-6.1-4.2c-2.3-1-4.8-1.5-7.3-1.4c-2.5,0-4.9,0.5-7.2,1.5
              c-2.3,1-4.4,2.4-6.3,4.2c-1.8,1.7-3.2,3.8-4.1,6.1C378.8,37.7,378.3,40.2,378.3,42.7z"/>
          </g>
        </g>
              <g id="Group_4" transform="translate(445.523 248.381)">
          <g id="Group_3" transform="translate(0 16.874)">
            <path id="Path_10" class="st1" d="M135.6,11.5c18.2,19.1-23.8,40.2,0.2,73.9c5.6,7.9,13.5,14.3,21.5,20.8c0,0-13.5-21.9-7.3-42.2
              c3.7-11.9,18.7-32.9,9-52.4c-0.1,0-13.7,0-20.6,0C137.3,11.5,135.6,11.5,135.6,11.5z"/>
            <path id="Path_11" class="st2" d="M149.2,67c0.2-1,0.5-2,0.8-3.1c1.3-4.1,3.9-9.4,6.4-15.3c0,0-25.2-0.2-25.3-0.1
              c-2.4,5.8-3.4,12.2-2.9,18.5C128.3,67.1,149.1,67.5,149.2,67z"/>
            <path id="Path_12" class="st2" d="M161.9,30.1c0.9-6.3-0.1-12.8-2.9-18.6c-0.1,0-13.7,0-20.6,0c-1,0-2.7,0-2.7,0
              c5.6,5.9,5.5,12,3.3,18.6C138.9,30.3,161.9,30.1,161.9,30.1z"/>
            <path id="Path_13" class="st2" d="M136,85.7c5.6,7.7,13.4,14,21.3,20.4c-3.7-6.4-6.3-13.3-7.8-20.5
              C149.4,85.5,135.9,85.6,136,85.7z"/>
          </g>
                <path id="Path_14" class="st2" d="M158.2,12.1c0,6-4.8,10.8-10.8,10.8c-6,0-10.8-4.8-10.8-10.8s4.8-10.8,10.8-10.8
            C153.3,1.2,158.2,6.1,158.2,12.1C158.2,12.1,158.2,12.1,158.2,12.1z"/>
        </g>
      </g>
      </svg>
  </div>
</template>

<script>
  export default {
    name: 'Logo',
    props: {
      width: {
        type: [
          Boolean,
          Number
        ],
        default: false
      },
      height: {
        type: [
          Boolean,
          Number
        ],
        default: false
      },
      textColor: {
        type: [String],
        default: '#fff'
      },
      dotColor: {
        type: [String],
        default: '#CC1337'
      }
    },
    data () {
      return {}
    },
    computed: {
      style () {
        let code = ''

        if (this.width) {
          code += `width: ${ this.width }px; `
        }

        if (this.height) {
          code += `height: ${ this.height }px; `
        }

        return code !== '' ? code : false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .st1{fill:#FC9200;}
  .st2{fill:#EC4115;}
</style>
