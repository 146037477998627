<template>
  <b-modal
    id="modal-templates-add"
    no-stacking
    @show="initializeModal"
  >
    <!--begin::header-->
    <template
      #modal-header
    >
      <h5 class="modal-title">
        {{ capitalize(trans('templates.modals.add.title')) }}
      </h5>
    </template>
    <!--begin::header-->

    <!--begin::body-->
    <template
      #default
    >
      <b-form
        class="form"
        novalidate
      >
        <!--begin::form-fields-->
        <div>
          <!--begin::form-name-->
          <b-form-group
            :label="trans('templates.modals.add.form.fields.name.title')"
            label-for="template_name"
            class="font-size-h6 font-weight-bolder text-dark"
          >
            <!--begin::form-name-field-->
            <b-form-input
              id="template_name"
              name="template_name"
              v-model="$v.name.$model"
              type="text"
              class="form-control form-control-solid h-auto py-4"
              :state="validateState('name')"
              :placeholder="trans('templates.modals.add.form.fields.name.placeholder')"
              aria-describedby="form-name-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback
              id="form-name-live-feedback"
            >
              <!--eslint-disable max-len -->
              <span v-if="$v.name.$errors[0] !== undefined && $v.name.$errors[0].$validator === 'alphaAndHyphenAndApostrophe'">
                {{ trans('templates.modals.add.form.fields.name.errors.alpha') }}
              </span>
              <span v-else>
                {{ trans('templates.modals.add.form.fields.name.errors.required') }}
              </span>
              <!--eslint-enable max-len -->
            </b-form-invalid-feedback>
            <!--end::form-name-field-->
          </b-form-group>
          <!--end::form-name-->

          <!--begin::form-description-->
          <b-form-group
            :label="trans('templates.modals.add.form.fields.desc.title')"
            labe-for="template_desc"
            class="font-size-h6 font-weight-bolder text-dark"
          >
            <!--begin::form-desc-area-->
            <b-form-textarea
              id="template_desc"
              name="template_desc"
              v-model="$v.desc.$model"
              class="form-control form-control-solid"
              :state="validateState('desc')"
              :placeholder="trans('templates.modals.add.form.fields.desc.placeholder')"
              aria-describedby="template_desc_live_feedback"
              rows="3"
              max-rows="5"
            ></b-form-textarea>
            <!--end::form-desc-area-->

            <b-form-invalid-feedback id="template_desc_live_feedback">
              {{ trans('templates.modals.add.form.fields.desc.error') }}
            </b-form-invalid-feedback>
          </b-form-group>
          <!--end::form-description-->

          <!--begin::form-customer-->
          <b-form-group
            :label="trans('templates.modals.add.form.fields.customer.title')"
            label-for="template_customer"
            class="font-size-h6 font-weight-bolder text-dark"
          >
            <Loader
              v-if="!fetchedCustomers"
              :text="trans('templates.modals.add.form.fields.customer.loadingCustomers')"
              loader-class="relative font-weight-bold font-size-sm"
              loader-style="margin-right: 80px;"
            ></Loader>
            <b-form-select
              v-else
              id="template_customer"
              name="template_customer"
              v-model="$v.customer.$model"
              :options="customersSelect"
              :state="validateState('customer')"
              class="form-control form-control-solid h-auto py-4"
              aria-describedby="form-customer-live-feedback"
            ></b-form-select>

            <b-form-invalid-feedback
              id="form-customer-live-feedback"
            >
              <span>
                {{ $v.customer.$errors }}
              </span>
            </b-form-invalid-feedback>
          </b-form-group>
          <!--end::form-customer-->
        </div>
        <!--end::form-fields-->
      </b-form>
    </template>
    <!--end::body-->

    <!--begin::footer-->
    <template
      #modal-footer="{ cancel }"
    >
      <b-btn
        variant="secondary"
        class="px-7 py-4"
        @click="cancel"
      >
        {{ capitalize(transChoice('global.cancel', 0)) }}
      </b-btn>
      <b-btn
        variant="primary"
        class="px-7 py-4"
        :class="submitButtonAnimation ? 'spinner spinner-light spinner-right pr-12' : ''"
        :disabled="submitButtonAnimation"
        @click="saveTemplate()"
      >
        <span v-if="submitButtonAnimation">
          {{ capitalize(trans('templates.modals.add.btn.loading')) }}
        </span>
        <span v-else>
          {{ capitalize(trans('templates.modals.add.btn.default')) }}
        </span>
      </b-btn>
    </template>
    <!--begin::footer-->
  </b-modal>
</template>

<script>
  import objectPath from 'object-path'
  import deepmerge from 'deepmerge'
  import { ref, computed } from '@vue/composition-api'
  import { trans, transChoice } from '@src/core/helpers/translate'
  import { capitalize } from '@src/core/helpers/textUtils'

  import useFormValidation from '@src/use/formValidation'

  import useVuelidate from '@vuelidate/core'
  import { required, minLength, helpers } from '@vuelidate/validators'
  import { TASKS } from '@src/store/tasks'

  export default {
    name: 'ModalTemplatesAdd',
    components: {
      Loader: () => import('@src/components/content/Loader')
    },
    setup (props, { root }) {
      const fb = require('@src/firebaseConfig') // eslint-disable-line global-require
      const formName = ref('')
      const formDesc = ref('')
      const formCustomer = ref(0)
      const submitted = ref(false)
      const submitButtonAnimation = ref(false)
      const customers = ref([])
      const fetchedCustomers = ref(false)

      const alphaAndHyphenAndApostrophe = helpers.regex(/^[0-9a-zÆØÅæøå'\-\s]*$/iu)

      const validationRules = {
        name: {
          required,
          alphaAndHyphenAndApostrophe,
          minLength: minLength(2)
        },
        desc: {
          required
        },
        customer: {
          required
        }
      }

      const $v = useVuelidate(
        validationRules,
        {
          name: formName,
          desc: formDesc,
          customer: formCustomer
        }
      )

      const { validateState } = useFormValidation($v, submitted)

      const saveTemplate = async () => {
        submitted.value = true
        submitButtonAnimation.value = true

        $v.value.$touch()
        if (Object.keys($v.value.$errors).length > 0) {
          submitButtonAnimation.value = false
          return // Show errors in form
        }

        const createTemplate = fb.functions.httpsCallable('backendTemplatesCreateTemplate')
        await createTemplate({
          data: {
            name: formName.value,
            desc: formDesc.value,
            customer: formCustomer.value
          }
        }).then((res) => {
          submitButtonAnimation.value = false
          const newTemplateId = objectPath.get(res, 'data.data.template.id', false)
          if (newTemplateId === false) {
            console.log('Error while fetching new ID')
          } else {
            const errors = objectPath.get(res, 'data.errors', false)
            if (errors !== false) {
              root.$bvToast.toast(trans('templates.modals.add.toast.error.msg', { error: errors[Object.keys(errors)[0]] }), { // eslint-disable-line max-len
                title: trans('templates.modals.add.toast.error.title'),
                variant: 'danger',
                solid: true,
                autoHideDelay: 5000
              })
            } else {
              root.$bvToast.toast(trans('templates.modals.add.toast.success.msg'), {
                title: trans('templates.modals.add.toast.success.title'),
                variant: 'success',
                solid: true,
                autoHideDelay: 5000
              })
              // Update the "oppgaver"-count
              root.$store.dispatch(TASKS.ACTIONS.FETCH_TASKS, { status: 0, limit: false }) // eslint-disable-line object-property-newline, max-len

              root.$bvModal.hide('modal-templates-add')
              root.$router.push(`/templates/edit/${ newTemplateId }`)
            }
          }
        }).catch((err) => {
          console.log('Errors while saving template:', err)
          submitButtonAnimation.value = false
          root.$bvToast.toast(trans('templates.modals.add.toast.error.msg', { error: err }), {
            title: trans('templates.modals.add.toast.error.title'),
            variant: 'danger',
            solid: true,
            autoHideDelay: 5000
          })
        })

        console.log('Saving template...')
        return true
      }

      const initializeModal = () => {
        formName.value = ''
        formDesc.value = ''
        formCustomer.value = 0
        submitted.value = false
        submitButtonAnimation.value = false

        const getCustomers = fb.functions.httpsCallable('backendCustomersGetCustomers')

        getCustomers({
          data: {
            limit: 1000,
            orderBy: 'name',
            sort: 'asc'
          }
        }).then((res) => {
          customers.value = res.data.result
          fetchedCustomers.value = true
        }).catch((err) => {
          console.log('Error occurred while fething the customers:', err)
        })
      }

      const customersSelect = computed(() => {
        console.log('CUSTOMERS ARRAY:', customers.value)
        const customersArray = Object.keys(customers.value).map((customerKey) => ({
          value: objectPath.get(customers, `value.${ customerKey }.id`, null),
          text: objectPath.get(customers, `value.${ customerKey }.name`, `(${ trans('templates.modals.add.form.fields.customer.missing') })`) // eslint-disable-line max-len
        }))

        const standardChoice = [
          {
            value: 0,
            text: trans('templates.modals.add.form.fields.customer.everybody')
          }
        ]

        return deepmerge(standardChoice, customersArray)
      })

      return {
        $v,
        trans,
        transChoice,
        capitalize,
        saveTemplate,
        validateState,
        initializeModal,
        customersSelect,
        fetchedCustomers,
        submitButtonAnimation
      }
    }
  }
</script>

<style lang="scss">

</style>
